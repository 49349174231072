@import url(https://cdnjs.cloudflare.com/ajax/libs/10up-sanitize.css/8.0.0/sanitize.min.css);
html,
body {
  font-family: 'IBM Plex Sans', 'Helvatica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 300;
  margin: .8rem 0; }

button {
  background: transparent;
  border: 0; }
  button:hover {
    cursor: pointer; }

a {
  text-decoration: none;
  color: #ccc; }
  a:visited {
    color: #ccc; }
  a:hover {
    color: white; }

input[type=range] {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */ }

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none; }

input[type=range]:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */ }

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent; }

/* WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1.1rem;
  width: 1.1rem;
  border-radius: 1.1rem;
  background: #ffffff;
  cursor: pointer;
  margin-top: -.42rem;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  /* Add cool effects to your sliders! */ }

/* Firefox */
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  height: 1.1rem;
  width: 1.1rem;
  border-radius: 1.1rem;
  background: #ffffff;
  cursor: pointer; }

/* IE */
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  height: 1.1rem;
  width: 1.1rem;
  border-radius: 1.1rem;
  background: #ffffff;
  cursor: pointer; }

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: white;
  border-radius: 1.3px;
  border: 0.2px solid #010101; }

input[type=range]:focus::-webkit-slider-runnable-track {
  background: white; }

input[type=range]::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: white;
  border-radius: 1.3px;
  border: 0.2px solid #010101; }

input[type=range]::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent; }

input[type=range]::-ms-fill-lower {
  background: white;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; }

input[type=range]:focus::-ms-fill-lower {
  background: white; }

input[type=range]::-ms-fill-upper {
  background: white;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; }

input[type=range]:focus::-ms-fill-upper {
  background: white; }

h1,
h2,
h3,
h4,
h5 {
  margin: 1rem 0 1rem;
  font-weight: 400;
  line-height: 1.15; }

h1 {
  font-size: 1.802rem; }

h2 {
  font-size: 1.602rem; }

h3 {
  font-size: 1.424rem; }

h4 {
  font-size: 1.266rem; }

h5 {
  font-size: 1.125rem; }

p {
  color: #666;
  line-height: 1.5; }

.home-container::before, .home-container::after {
  content: "";
  position: absolute;
  height: 40px;
  width: 100%; }

.home-container::before {
  top: 0;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0)), color-stop(80%, rgba(0, 0, 0, 0.8)), to(black));
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 80%, black 100%);
  z-index: 1; }

.home-container::after {
  bottom: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(80%, rgba(0, 0, 0, 0.8)), to(black));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 80%, black 100%); }

.home-section {
  height: calc( (1vh * 100) - 60px - 156px);
  height: calc( (var(--vh, 1vh) * 100) - 60px - 156px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  justify-content: center; }

.current-show {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1rem;
  margin-top: -3rem; }

.current-show-info {
  margin-bottom: 2.5rem; }

.current-show-header-container {
  display: flex;
  margin-bottom: .75rem;
  align-items: center; }

.current-show-time {
  margin-left: 1rem;
  font-size: 1.25rem;
  font-weight: 600; }

.current-show-type-text {
  background-color: #aaa;
  padding: 0.4rem 0.7rem;
  font-weight: 600; }
  .current-show-type-text.live {
    background-color: #FF4747; }

.current-show-title {
  width: 90%; }

.current-show-title-text {
  text-transform: capitalize;
  font-size: calc(16px + 2.5*2vw);
  font-weight: 400; }

.current-show-dj {
  font-size: 1.125rem;
  width: 90%; }

.next-live-show-date, .next-live-show-time {
  display: inline-block; }

.next-show-info {
  margin-top: 0.5rem; }

.next-live-show-wrapper {
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  min-height: 75px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column; }

.next-live-show {
  padding-right: 1rem;
  margin-bottom: 1rem;
  bottom: 156px; }

.next-live-show-label {
  color: #666;
  margin-bottom: 8px; }

.next-live-show-header-container {
  display: flex; }

.next-live-show-header,
.next-live-show-date,
.next-live-show-time {
  font-size: 1rem;
  font-weight: 300; }

.next-live-show-header {
  white-space: nowrap;
  margin-top: 0;
  margin-bottom: 0.5rem; }

.next-live-show-title,
.next-live-show-title-text {
  text-transform: capitalize;
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 400; }

.next-live-show-date,
.next-live-show-time {
  font-size: 1rem; }

@media screen and (max-width: 350px) {
  .current-show-type {
    margin-bottom: .5rem; }
  .current-show-title-text {
    font-size: calc(16px + 2.5*2vw); }
  .current-show-title {
    margin-bottom: 0rem; }
  .next-live-show {
    margin-bottom: 0; }
  .next-live-show-title,
  .next-live-show-title-text,
  .next-live-show-dj {
    font-size: 1.15rem; } }

@media screen and (min-width: 450px) {
  .current-show-title-text {
    font-size: calc(32.86667px + 0.93333vw); } }

@media screen and (min-width: 930px) {
  .page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .home-section {
    display: grid;
    grid-template: 1fr / 5fr 3fr;
    grid-gap: 16px 48px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
    align-items: start;
    position: relative;
    overflow: visible; }
    .home-section::after {
      content: "";
      background-color: #565656;
      opacity: .6;
      width: 1px;
      height: 110%;
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      position: absolute;
      left: calc( 62.5% - 48px); }
  .current-show-title-text {
    margin: 0; }
  .current-show {
    width: 50%; }
  .current-show-header-container {
    margin-top: 0px;
    grid-area: 1 / 1 / span 1 / span 1; }
  .current-show-info {
    grid-area: 2 / 1 / span 1 / span 1;
    margin-bottom: 0; }
  .current-show-title-text {
    margin-bottom: 1.5rem;
    font-weight: 400;
    font-size: calc(25.94595px + 1.08108vw); }
  .current-show-dj {
    grid-area: 3 / 1 / span 1 / span 1;
    color: #dedede; }
  .next-live-show-wrapper {
    position: relative;
    width: 40%;
    padding-left: 3%;
    padding-top: 1rem;
    margin-top: -1.6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
  .next-show-info {
    grid-area: 2 / 2 / span 1 / span 1;
    margin-top: 0; }
  .next-live-show {
    position: relative;
    bottom: auto;
    bottom: initial;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    .next-live-show::before {
      content: "";
      position: absolute;
      left: -15%;
      width: 1px;
      height: 150%;
      background-color: #303030; }
  .next-live-show-label {
    display: none; }
  .next-live-show-header {
    margin-top: 0; }
  .next-live-show-header-container {
    position: relative;
    top: 0.5rem;
    color: #AAA; }
  .next-live-show-header-container,
  .next-live-show-header,
  .next-live-show-header-details,
  .next-live-show-date,
  .next-live-show-time {
    font-size: calc(5.94595px + 1.08108vw); }
  .next-live-show-details {
    margin-bottom: 0; }
  .next-live-show-title {
    margin-bottom: 1.5rem;
    font-weight: 400;
    font-size: calc(25.94595px + 1.08108vw); }
  .next-show-title-text {
    margin-bottom: 1rem; }
  .next-live-show-dj {
    font-size: 1.125rem;
    color: #f3f3f3; } }

@media screen and (min-width: 1200px) {
  .current-show-title-text, .next-show-title-text {
    font-size: calc( 3vw); }
  .next-live-show-header-container,
  .next-live-show-header,
  .next-live-show-header-details,
  .next-live-show-date,
  .next-live-show-time {
    font-size: 1.4vw; }
  .current-show-dj, .next-live-show-dj {
    font-size: 1.4vw; } }

@media screen and (min-width: 1286px) {
  .next-live-show-header-container,
  .next-live-show-header,
  .next-live-show-header-details,
  .next-live-show-date,
  .next-live-show-time {
    font-size: 18px; } }

@media screen and (min-width: 1429px) {
  .current-show-dj, .next-live-show-dj {
    font-size: 20px; }
  .next-live-show-header-container,
  .next-live-show-header,
  .next-live-show-header-details,
  .next-live-show-date,
  .next-live-show-time {
    font-size: 18px; } }

.fb-home-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }

.fb-text {
  font-weight: 100;
  color: #555;
  font-size: 40px; }

.fb-text-link {
  position: relative;
  white-space: nowrap; }

.fb-text-link::after {
  content: '';
  position: absolute;
  height: 2px;
  width: calc( 100% - 2px);
  bottom: 2%;
  left: 2px;
  background-color: white;
  -webkit-transform: translateX(-2.5%) scaleX(0);
          transform: translateX(-2.5%) scaleX(0);
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  opacity: .3;
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.fb-text-link:hover::after {
  opacity: 1;
  -webkit-transform: translateX(-2.5%) scaleX(1.04);
          transform: translateX(-2.5%) scaleX(1.04); }

@media screen and (min-width: 350px) {
  .fb-text {
    font-size: calc(30.78947px + 2.63158vw); } }

@media screen and (min-width: 470px) {
  .fb-text {
    max-width: 90%; } }

@media screen and (min-width: 600px) {
  .fb-text {
    max-width: 70%; } }

@media screen and (min-width: 1000px) {
  .fb-text {
    max-width: 60%; } }

@media screen and (min-width: 1200px) {
  .fb-text {
    max-width: 700px;
    font-size: 62px; } }

.banner-container {
  display: none;
  height: 100vh;
  width: 320px; }

.svg-container {
  background: -webkit-gradient(linear, left top, right bottom, from(#A970FF), to(#0076FF));
  background: linear-gradient(to right bottom, #A970FF, #0076FF);
  width: 320px;
  overflow: hidden; }

svg {
  width: 100%;
  height: 100vh;
  -webkit-transform: scale(1.2);
          transform: scale(1.2); }

@media (min-width: 1200px) {
  .banner-container, .svg-container {
    width: 20vw; } }

.site-title {
  font-size: 1.25rem;
  margin-left: 0.2rem;
  margin: 0; }

.navigation {
  color: white;
  outline-offset: -1px;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 500;
  padding-top: 1rem; }

.navigaton ul {
  padding: 0;
  margin: 0; }

.navigation li {
  display: inline-block;
  padding: 5px; }

.navigation-btn {
  z-index: 1;
  padding: 0;
  margin: 0 0.75rem 0 1rem;
  -webkit-transform: translateY(0.125rem);
          transform: translateY(0.125rem); }
  .navigation-btn .navigation-icon {
    width: 1.6rem;
    height: 1.6rem; }

.icon-canvas {
  height: 30px;
  width: 30px;
  position: relative; }

.hamburger {
  position: absolute;
  height: 2px;
  width: 70%;
  background-color: white;
  left: 15%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 0.5s all cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.5s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .hamburger::before {
    position: absolute;
    left: 0;
    content: '';
    height: 2px;
    width: 100%;
    background-color: white;
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-transition: 0.5s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.5s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .hamburger::after {
    position: absolute;
    left: 0;
    content: '';
    height: 2px;
    width: 100%;
    background-color: white;
    -webkit-transform: translateY(7px);
            transform: translateY(7px);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-transition: 0.5s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.5s all cubic-bezier(0.165, 0.84, 0.44, 1); }

.icon-navigation.open .hamburger::before {
  -webkit-transform: translateY(0.6px) translateX(-1px) rotateZ(-45deg) scaleX(0.5);
          transform: translateY(0.6px) translateX(-1px) rotateZ(-45deg) scaleX(0.5); }

.icon-navigation.open .hamburger::after {
  -webkit-transform: translateY(-0.6px) translateX(-1px) rotateZ(45deg) scaleX(0.5);
          transform: translateY(-0.6px) translateX(-1px) rotateZ(45deg) scaleX(0.5); }

.navigation-list {
  position: absolute;
  height: calc( 100vh - 156px);
  width: 100vw;
  padding: 0 1rem 0;
  font-size: 1.25rem;
  font-weight: 300;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s all cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.5s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list .navigation-item {
    margin-bottom: 1rem;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0; }
  .navigation-list.open .navigation-item:nth-child(1) {
    -webkit-transition: 0.5s 0.15s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.5s 0.15s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.open .navigation-item:nth-child(2) {
    -webkit-transition: 0.5s 0.2s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.5s 0.2s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.open .navigation-item:nth-child(3) {
    -webkit-transition: 0.5s 0.25s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.5s 0.25s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.open .navigation-item:nth-child(4) {
    -webkit-transition: 0.5s 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.5s 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.open .navigation-item:nth-child(5) {
    -webkit-transition: 0.5s 0.35s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.5s 0.35s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.open {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    height: calc( 100vh - 156px);
    width: 100vw;
    margin: 0;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1; }
    .navigation-list.open .navigation-item {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      opacity: 1; }
  .navigation-list.closing {
    -webkit-transition: .5s .5s;
    transition: .5s .5s;
    opacity: 0; }
    .navigation-list.closing .navigation-item:nth-child(1) {
      -webkit-transition: 0.4s 0.2s all cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: 0.4s 0.2s all cubic-bezier(0.165, 0.84, 0.44, 1); }
    .navigation-list.closing .navigation-item:nth-child(2) {
      -webkit-transition: 0.4s 0.15s all cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: 0.4s 0.15s all cubic-bezier(0.165, 0.84, 0.44, 1); }
    .navigation-list.closing .navigation-item:nth-child(3) {
      -webkit-transition: 0.4s 0.1s all cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: 0.4s 0.1s all cubic-bezier(0.165, 0.84, 0.44, 1); }
    .navigation-list.closing .navigation-item:nth-child(4) {
      -webkit-transition: 0.4s 0.05s all cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: 0.4s 0.05s all cubic-bezier(0.165, 0.84, 0.44, 1); }
    .navigation-list.closing .navigation-item:nth-child(5) {
      -webkit-transition: 0.4s 0s all cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: 0.4s 0s all cubic-bezier(0.165, 0.84, 0.44, 1); }
    .navigation-list.closing .navigation-item {
      margin-bottom: 1rem;
      -webkit-transform: translateY(30px);
              transform: translateY(30px);
      opacity: 0; }

.navigation-item-link .navigation-item-link-text {
  position: relative; }

.navigation-item-link .navigation-item-link-text::after {
  content: '';
  position: absolute;
  height: 1px;
  width: calc( 100% - 2px);
  bottom: -8px;
  left: 2px;
  background-color: white;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  opacity: .3;
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.navigation-item-link .navigation-item-link-text:hover::after {
  opacity: 1;
  -webkit-transform: scaleX(1);
          transform: scaleX(1); }

.navigation-item-link:hover .navigation-item-link-img {
  opacity: 1; }

.navigation-item-link:focus {
  box-shadow: none; }
  .navigation-item-link:focus .navigation-item-link-text::after {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }

.navigation-item-link.nav-selected {
  font-weight: 500;
  color: white; }
  .navigation-item-link.nav-selected .navigation-item-link-img {
    opacity: 1; }

.navigation-item-link-share {
  margin-left: auto;
  margin-top: 0.25rem; }
  .navigation-item-link-share .navigation-item-link-img {
    margin-right: 0.75rem; }

.navigation-item-link-img {
  margin-right: 1.5rem;
  opacity: .7; }
  .navigation-item-link-img img {
    width: 1.6rem;
    height: 1.6rem;
    vertical-align: bottom;
    -webkit-transform: translateY(0.125rem);
            transform: translateY(0.125rem); }

.site-title {
  display: flex;
  align-items: center; }
  .site-title .navigation-item-link.nav-selected {
    font-weight: 300; }

.betaTag {
  display: inline-block;
  padding: 0 0.5rem;
  line-height: 18px;
  height: 18px;
  border-radius: 20px;
  background: -webkit-gradient(linear, left top, right bottom, from(#0076FF), to(#9166db));
  background: linear-gradient(to right bottom, #0076FF, #9166db);
  font-size: .8rem;
  margin-left: 0.75rem;
  letter-spacing: 3px;
  font-feature-settings: "c2sc";
  font-variant: small-caps;
  font-weight: 500;
  white-space: nowrap;
  text-indent: 0.2rem; }

@media only screen and (min-width: 550px) {
  .navigation-list .navigation-item {
    margin-left: 1.5rem; } }

@media only screen and (min-width: 630px) {
  .navigation {
    align-items: flex-end;
    padding-top: calc(4.42105px + 2.10526vw); }
  .navigation-btn {
    display: none; }
  .site-title {
    margin: 0 0 0 1rem;
    font-size: calc(14.21053px + 1.05263vw);
    font-weight: 300;
    flex-shrink: 0;
    align-items: flex-end; }
    .site-title .navigation-item-link.nav-selected {
      font-weight: 300; }
  .betaTag {
    margin-bottom: 0.25rem; }
  .navigation-list {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    height: 2rem;
    margin-top: 0;
    margin-bottom: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: none !important;
    transition: none !important;
    opacity: 1;
    visibility: visible; }
    .navigation-list .navigation-item {
      opacity: 1;
      margin-left: 0.75rem;
      margin-bottom: 0;
      font-size: 1rem;
      padding-top: 0.75rem;
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-transition: none !important;
      transition: none !important; }
    .navigation-list .navigation-item-link-img {
      display: none; }
    .navigation-list .navigation-item-home,
    .navigation-list .navigation-item-home--alt,
    .navigation-list .navigation-item-join {
      display: none; }
  .navigation-item-link-share {
    order: 1;
    margin-left: 0; } }

@media only screen and (min-width: 930px) {
  .navigation {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 40px; }
  .navigation-list .navigation-item {
    font-size: 1rem;
    padding-top: 0.75rem; }
  .site-title {
    font-size: 24px; } }

#calendar {
  font-family: 'IBM Plex Sans';
  width: 100%;
  height: calc( (1vh * 100) - 60px - 156px);
  height: calc( (var(--vh, 1vh) * 100) - 60px - 156px);
  max-height: calc(100vh - 60px - 156px);
  overflow-x: hidden;
  overflow-y: scroll; }
  #calendar::-webkit-scrollbar {
    width: 0 !important; }

.fc-month-view .fc-event {
  background-color: #222;
  border: 1px solid #333; }

.fc-state-active {
  font-weight: bold; }

.fc-button {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial;
  color: white;
  border: 0;
  box-shadow: none; }

.fc td, .fc th {
  border-color: #222; }

.fc-list-view {
  border: 0; }

/* LIST VIEW STYLES */
.fc-list-table .fc-event-dot {
  background-color: #222; }

.fc-unthemed .fc-list-table .fc-list-heading td {
  background-color: transparent;
  background-color: initial; }

.fc-unthemed .fc-list-table .fc-list-item:hover td {
  background-color: #111; }

.fc-list-table td.fc-widget-header {
  border: 0;
  padding-top: 1.5rem; }

.fc-toolbar {
  padding-top: 1.5rem;
  padding-bottom: 2.5rem;
  margin-bottom: 0;
  position: fixed;
  width: calc( 100vw - 2rem);
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, from(black), color-stop(37%, black), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(to bottom, black 0%, black 37%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
  /* IE6-9 */
  z-index: 2; }
  .fc-toolbar.fc-toolbar {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px); }
  .fc-toolbar h2 {
    font-size: 1.125rem;
    font-weight: 500; }
  .fc-toolbar .fc-left {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .fc-toolbar .fc-left h2 {
      font-size: 1.5rem;
      font-weight: 300;
      font-size: 1.125rem;
      width: 5.25rem; }
    .fc-toolbar .fc-left .fc-navigateBack-button {
      padding-left: 0;
      padding-right: 0.25rem; }
  .fc-toolbar .fc-center, .fc-toolbar .fc-clear {
    display: none; }

.fc-prev-button,
.fc-next-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  position: relative; }

.fc-listMonth-button, .fc-month-button {
  display: none; }

.calendar-btn img {
  width: 1.5rem;
  height: 1.5rem; }

.fc-view-container {
  padding-top: 3rem;
  margin-bottom: 125px; }

.fc-scroller {
  height: 100% !important; }

.fc-list-table {
  font-size: .8rem; }
  .fc-list-table td {
    padding: 1rem 0; }
  .fc-list-table .fc-widget-header {
    padding-right: 0; }
  .fc-list-table .fc-list-item-marker {
    display: none; }
  .fc-list-table .fc-list-item-time {
    width: 20%;
    padding-right: 2rem; }
  .fc-list-table .fc-list-item-title {
    width: 75%;
    padding-right: 0; }
    .fc-list-table .fc-list-item-title a:hover {
      text-decoration: none; }

.fc-unthemed .fc-list-empty,
.fc-list-empty {
  background-color: black;
  height: 70vh; }

.fc-unthemed td.fc-today {
  background: #222; }

@media screen and (min-width: 550px) {
  .fc-toolbar.fc-toolbar {
    width: calc( 100% - 2.5rem); } }

@media screen and (min-width: 930px) {
  .fc-toolbar.fc-toolbar {
    width: calc( 100% - 5rem); }
  .fc-view-container {
    padding-top: 0;
    margin-bottom: 0; }
  .fc-head-container.fc-widget-header {
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent; }
  .fc-widget-header tr {
    border-color: transparent; }
  th.fc-day-header.fc-widget-header {
    color: #666;
    font-weight: 300;
    border-color: transparent;
    padding-bottom: 0.75rem; }
  .fc-listMonth-button, .fc-month-button {
    display: block; }
  .fc-month-view,
  .fc-list-view {
    padding-top: 4.5rem; }
  .fc-list-view {
    padding-bottom: 150px; } }

@media screen and (min-width: 930px) {
  .fc-toolbar {
    width: calc( 100% - 1rem);
    padding-top: 2rem; } }

@media screen and (min-width: 1200px) {
  #calendar {
    height: calc(100vh - 60px - 156px);
    max-height: calc(100vh - 60px - 156px);
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative; }
  .main-content {
    height: calc(100vh - 60px - 156px);
    max-height: calc(100vh - 60px - 156px);
    overflow: hidden;
    padding: 0 1rem;
    flex: 1 1;
    position: relative; }
  .fc-right {
    float: left; }
  .fc-toolbar {
    display: flex; } }

.audio-player-wrapper {
  width: 100%;
  margin-bottom: 50px; }

.audio-player {
  bottom: 0%;
  width: 100vw;
  text-align: center;
  background-color: black;
  height: 156px;
  padding: 0px 16px 16px 16px;
  z-index: 1000; }
  .audio-player-wrapper {
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative; }
    .audio-player-wrapper:before {
      content: "";
      width: 100%;
      height: 90px;
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(80%, rgba(0, 0, 0, 0.8)), to(black));
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 80%, black 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
      /* IE6-9 */
      position: absolute;
      left: 0;
      top: 0;
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      pointer-events: none;
      z-index: 1; }
  .audio-player.full .audio-player-wrapper:before {
    display: none; }
  .audio-player #canvas {
    width: 100%;
    height: 40px;
    margin-bottom: 0.5rem; }
  .audio-player .progress-bar {
    width: 100%; }
  .audio-player .progress-bar-time {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px; }
  .audio-player .progress-bar-timeline {
    position: relative;
    position: relative;
    height: 10px; }
  .audio-player .progress-bar-timeline-full {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #979797;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .audio-player .progress-bar-timeline-current {
    position: absolute;
    width: 100%;
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    height: 10px;
    background-color: #f3f3f3; }
    .audio-player .progress-bar-timeline-current::after {
      content: "";
      position: absolute;
      height: 10px;
      width: 10px;
      background-color: #FF4747;
      right: 0; }
  .audio-player .content-wrapper {
    display: flex; }
  .audio-player .stream-wrapper {
    width: calc( 100% - 80px);
    padding-right: 8px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
  .audio-player .title-wrapper {
    overflow: hidden;
    position: relative;
    height: auto; }
    .audio-player .title-wrapper:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 20%;
      right: 0;
      bottom: 0;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.64+37,1+72,1+100 */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0)), color-stop(37%, rgba(0, 0, 0, 0.64)), color-stop(72%, black), to(black));
      background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.64) 37%, black 72%, black 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 );
      /* IE6-9 */ }
  .audio-player .subtitle-wrapper {
    text-align: left;
    font-size: 12px; }
  .audio-player .timecode {
    font-weight: bold; }
  .audio-player .active-listeners {
    font-weight: bold; }

@-webkit-keyframes slide {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  50% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 1; }
  51% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0; }
  52% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0; }
  53% {
    opacity: 1; }
  60% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@keyframes slide {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  50% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 1; }
  51% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0; }
  52% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0; }
  53% {
    opacity: 1; }
  60% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }
  .audio-player .title {
    font-weight: 300;
    font-size: 14px;
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .audio-player .title.slide {
      -webkit-animation: slide 14s 3s linear infinite;
              animation: slide 14s 3s linear infinite; }
  .audio-player .title-label {
    display: none; }
  .audio-player .title-value {
    white-space: nowrap; }
  .audio-player .content-wrapper {
    text-align: center; }
  .audio-player .controls {
    width: 80px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
  .audio-player .control-btn {
    height: auto;
    width: 37%;
    background: transparent;
    border: 0;
    position: relative; }
    .audio-player .control-btn:focus {
      outline: 0; }
    .audio-player .control-btn img {
      height: 1.4rem;
      width: 1.4rem; }
  .audio-player .control-btn.volume-btn {
    height: 32px;
    width: 30px; }
  .audio-player .volume-btn img {
    height: 1.4rem;
    width: 1.4rem; }
  .audio-player .control-btn.playback-btn {
    height: 32px;
    width: 30px; }
  .audio-player .icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); }
  .audio-player .btn-anim-enter img {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0; }
  .audio-player .btn-anim-enter-active img {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
    -webkit-transition: all 200ms;
    transition: all 200ms; }
  .audio-player .btn-anim-exit img {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1; }
  .audio-player .btn-anim-exit-active img {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
    -webkit-transition: all 200ms;
    transition: all 200ms; }
  .audio-player .playback-btn .icon-canvas {
    height: 30px;
    width: 30px;
    position: relative;
    z-index: 1; }
  .audio-player .playback-icon {
    position: relative;
    width: 0;
    height: 0;
    top: 6px;
    right: -8px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 13px solid black;
    -webkit-transition: 0.1s all ease-out;
    transition: 0.1s all ease-out; }
  .audio-player .playback-icon::after {
    content: '';
    position: absolute;
    top: -15px;
    left: -13px;
    position: absolute;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 18px solid white;
    -webkit-transform: translateX(-2px) translateY(3px);
            transform: translateX(-2px) translateY(3px);
    z-index: -1;
    -webkit-transition: width 0s,  top 0s, left 0s, border 0s,  -webkit-transform .1s ease-out;
    transition: width 0s,  top 0s, left 0s, border 0s,  -webkit-transform .1s ease-out;
    transition: width 0s,  top 0s, left 0s, transform .1s ease-out, border 0s;
    transition: width 0s,  top 0s, left 0s, transform .1s ease-out, border 0s,  -webkit-transform .1s ease-out; }
  .audio-player .playback-icon::before {
    content: '';
    position: absolute;
    content: '';
    width: 5px;
    height: 18px;
    outline: 1.5px solid white;
    position: absolute;
    top: -12px;
    left: -3px;
    position: absolute;
    border: 0;
    z-index: -1;
    -webkit-transform: translateX(0px) translateY(3px);
            transform: translateX(0px) translateY(3px);
    opacity: 0;
    -webkit-transition: 0.1s all ease-out;
    transition: 0.1s all ease-out;
    -webkit-transition-delay: 0s;
            transition-delay: 0s; }
  .audio-player .icon-playback.pause .playback-icon {
    border-color: transparent; }
    .audio-player .icon-playback.pause .playback-icon::after {
      content: '';
      width: 5px;
      height: 18px;
      outline: 1.5px solid white;
      top: -12px;
      left: -1px;
      border: 0;
      z-index: -1;
      -webkit-transform: translateX(-14px) translateY(3px);
              transform: translateX(-14px) translateY(3px); }
    .audio-player .icon-playback.pause .playback-icon::before {
      opacity: 1; }
  .audio-player .playback-btn.pulse {
    position: relative; }
    .audio-player .playback-btn.pulse::before {
      pointer-events: none;
      opacity: 0;
      content: "";
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      border-radius: 100px;
      -webkit-animation: pulse 3s infinite;
              animation: pulse 3s infinite; }
    .audio-player .playback-btn.pulse::after {
      pointer-events: none;
      opacity: 0;
      content: "";
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      border-radius: 100px;
      -webkit-animation: pulse-inner 1.5s infinite .1s;
              animation: pulse-inner 1.5s infinite .1s; }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: .5; }
  50% {
    -webkit-transform: scale(10);
            transform: scale(10);
    opacity: 0; }
  100% {
    -webkit-transform: scale(10);
            transform: scale(10);
    opacity: 0; } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: .5; }
  50% {
    -webkit-transform: scale(10);
            transform: scale(10);
    opacity: 0; }
  100% {
    -webkit-transform: scale(10);
            transform: scale(10);
    opacity: 0; } }

@-webkit-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: .5; }
  50% {
    -webkit-transform: scale(8);
            transform: scale(8);
    opacity: 0; }
  100% {
    -webkit-transform: scale(8);
            transform: scale(8);
    opacity: 0; } }

@keyframes pulse-inner {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: .5; }
  50% {
    -webkit-transform: scale(8);
            transform: scale(8);
    opacity: 0; }
  100% {
    -webkit-transform: scale(8);
            transform: scale(8);
    opacity: 0; } }
  .audio-player .volume-slider-wrapper {
    position: absolute;
    top: -25%;
    right: 0%;
    height: 7rem;
    width: 40px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: black;
    border-radius: 100px;
    -webkit-transform: translateY(-80%);
            transform: translateY(-80%);
    opacity: 0;
    pointer-events: none;
    -webkit-transition: 0.8s all cubic-bezier(0.19, 1, 0.22, 1);
    transition: 0.8s all cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 0; }
    .audio-player .volume-slider-wrapper.open {
      opacity: 1;
      pointer-events: all;
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%); }
    .audio-player .volume-slider-wrapper .volume-slider-label {
      position: absolute;
      opacity: 0; }
    .audio-player .volume-slider-wrapper .volume-slider {
      color: white;
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      width: 5rem; }

@media screen and (min-width: 930px) {
  .audio-player {
    height: 200px; }
  .audio-player-wrapper {
    height: 180px;
    padding-left: 1.5rem;
    padding-right: 1.5rem; } }

@media screen and (min-width: 1200px) {
  .audio-player {
    width: 100%;
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .audio-player-wrapper {
    margin: 0 auto;
    padding: 0; } }

[role="alert"] {
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  background: -webkit-gradient(linear, left top, right bottom, from(#0076FF), to(#9166db));
  background: linear-gradient(to right bottom, #0076FF, #9166db);
  text-align: center;
  padding: 0.5rem;
  border-bottom: 1px solid #ffffff75; }

body {
  background: black;
  color: white;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  /* prevent overscroll bounce*/
  overflow-y: scroll;
  scrollbar-width: none; }

a, button {
  -webkit-transition: .4s ease-in-out;
  transition: .4s ease-in-out; }
  a:focus, button:focus {
    outline: 0; }

button {
  padding: 5px;
  margin: 5px; }

#root {
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw; }

.App {
  width: 100vw;
  max-height: calc(1vh * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  overflow: hidden; }

.App-content {
  display: flex;
  flex-direction: column;
  height: calc( 100vh - 156px);
  height: calc( 1vh * 100 - 156px);
  height: calc( var(--vh, 1vh) * 100 - 156px);
  max-height: calc( 1vh * 100 - 156px);
  max-height: calc( var(--vh, 1vh) * 100 - 156px); }

.non-error-content {
  display: flex; }

.non-banner-content {
  overflow: hidden; }

.navigation-wrapper {
  flex: 0 0;
  position: relative;
  min-height: 60px;
  width: 100%; }
  .navigation-wrapper .navigation {
    width: 100%; }

.page {
  position: absolute;
  width: calc( 100% - 2.5rem); }
  .page::-webkit-scrollbar {
    width: 0 !important;
    background: transparent; }

.page-enter {
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px); }

.page-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: opacity 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms, -webkit-transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
  transition: opacity 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms, -webkit-transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
  transition: opacity 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms, transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
  transition: opacity 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms, transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms, -webkit-transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms; }

.page-exit {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px); }

.page-exit-active {
  opacity: 0;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, transform 200ms;
  transition: opacity 200ms, transform 200ms, -webkit-transform 200ms; }

.main-content {
  height: calc(100vh - 60px - 156px);
  max-height: calc(100vh - 60px - 156px);
  overflow: hidden;
  padding: 0 1rem;
  flex: 1 1;
  position: relative; }
  .main-content::-webkit-scrollbar {
    width: 0 !important;
    background: transparent; }

@media screen and (min-width: 930px) {
  .page {
    width: calc( 100% - 5rem); }
  .main-content {
    padding: 0 2.5rem; }
  .App-content {
    height: calc( 100vh - 200px);
    height: calc( 1vh * 100 - 200px);
    height: calc( var(--vh, 1vh) * 100 - 200px);
    max-height: calc( 1vh * 100 - 200px);
    max-height: calc( var(--vh, 1vh) * 100 - 200px); } }

@media only screen and (min-width: 1200px) {
  .audio-player {
    right: 0; }
  .App-content {
    margin: 0 auto; }
  .banner-container {
    display: block; } }

@-webkit-keyframes FadeAnim {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes FadeAnim {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

