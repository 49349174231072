@import '../mixins';

.home-container {
    &::before, &::after {
        content: "";
        position: absolute;
        height: 40px;
        width: 100%;
    }

    &::before {
        top: 0;
        background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 80%, black 100%);
        z-index: 1;
    }

    &::after {
        bottom: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 80%, black 100%);
    }
}

.home-section {
    height: calc( (var(--vh, 1vh) * 100) - #{$navigation-size} - #{$audio-player-size-sm});
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    justify-content: center;
}

.current-show {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;
    margin-top: -$ibmr-space-xxlg;
}

.current-show-info {
    margin-bottom: $ibmr-space-xlg;
}

.current-show-header-container {
    display: flex;
    margin-bottom: .75rem;
    align-items: center;
}

.current-show-time {
    margin-left: 1rem;
    font-size: 1.25rem;
    font-weight: 600;
}

.current-show-type-text {
    background-color: #aaa;
    padding: 0.4rem 0.7rem;
    font-weight: 600;

    &.live {
        background-color: #FF4747
    }
}

.current-show-title {
    width: 90%;
}

.current-show-title-text {
    text-transform: capitalize;
    font-size: calc(16px + 2.5*2vw);
    font-weight: 400;
}

.current-show-dj {
    font-size: 1.125rem;
    width: 90%;
}

.next-live-show-date, .next-live-show-time {
    display: inline-block;
}

.next-show-info {
    margin-top: $ibmr-space-xsm;
}

.next-live-show-wrapper {
    padding-top: $ibmr-space-md;
    margin-top: $ibmr-space-md;
    margin-bottom: $ibmr-space-xsm;
    min-height: 75px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.next-live-show {
    padding-right: $ibmr-space-xmd;
    margin-bottom: $ibmr-space-xmd;
    bottom: $audio-player-size-sm;
}

.next-live-show-label {
    color: #666;
    margin-bottom: 8px;
}

.next-live-show-header-container {
    display: flex;
}

.next-live-show-header,
.next-live-show-date,
.next-live-show-time {
    font-size: 1rem;
    font-weight: 300;
}

.next-live-show-header {
    white-space: nowrap;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.next-live-show-title,
.next-live-show-title-text {
    text-transform: capitalize;
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 400;
} 

.next-live-show-date,
.next-live-show-time {
    font-size: 1rem;
}

@media screen and (max-width: 350px) {

    .current-show-type {
        margin-bottom: .5rem;
    }

    .current-show-title-text {
        font-size: calc(16px + 2.5*2vw);
    }

    .current-show-title {
        margin-bottom: 0rem;
    }

    .next-live-show {
        margin-bottom: 0;
    }

    .next-live-show-title, 
    .next-live-show-title-text, 
    .next-live-show-dj {
        font-size: 1.15rem;
    }
}

@media screen and (min-width: 450px) {
    .current-show-title-text{
        // Interpolate navigation padding top between a min and max viewport width
        font-size: lerp(38px, 45px, $nav-padding-top-small, 1300px);
    }
}

@media screen and (min-width: $nav-padding-top-large) {

    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    // Horizontal layout
    .home-section {
        display: grid;
        grid-template: 1fr / 5fr 3fr;
        grid-gap: 16px 48px;
        height: auto;
        margin-top: auto;
        margin-bottom: auto;
        align-items: start;
        position: relative;
        overflow: visible;

        &::after {
            content: "";
            background-color: #565656;
            opacity: .6;
            width: 1px;
            height: 110%;
            transform: translateY(-5%);
            position: absolute;
            left: calc( 62.5% - 48px);
        
        }
    }

    // Current Show Styles
    .current-show-title-text {
        margin: 0;
    }

    .current-show {
        width: 50%;
    }

    .current-show-header-container {
        margin-top: 0px;
        grid-area: 1 / 1 / span 1 / span 1;
    }

    .current-show-info {
        grid-area: 2 / 1 / span 1 / span 1;
        margin-bottom: 0;
    }

    .current-show-title-text {
        margin-bottom: $ibmr-space-md;
        font-weight: 400;
        font-size: lerp(36px, 40px, $nav-padding-top-large, 1300px);
    }

    .current-show-dj {
        grid-area: 3 / 1 / span 1 / span 1;
        color: #dedede;
    }

    // Next Live Show Styles

    .next-live-show-wrapper {
        position: relative;
        width: 40%;
        padding-left: 3%;
        padding-top: 1rem;
        margin-top: -1.6rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        
    }

    .next-show-info {
        grid-area: 2 / 2 / span 1 / span 1;
        margin-top: 0;
    }

    .next-live-show {
        position: relative;
        bottom: initial;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        &::before {
            content: "";
            position: absolute;
            left: -15%;
            width: 1px;
            height: 150%;
            background-color: #303030;
        }
    }

    .next-live-show-label {
        display: none;
    }

    .next-live-show-header {
        margin-top: 0;
    }

    .next-live-show-header-container {
        position: relative;
        top: $ibmr-space-xsm;
        color: #AAA;
    }

    .next-live-show-header-container,
    .next-live-show-header,
    .next-live-show-header-details,
    .next-live-show-date,
    .next-live-show-time {
        font-size: lerp(16px, 20px, $nav-padding-top-large, 1300px);
    }

    .next-live-show-details {
        margin-bottom: 0;
    }

    .next-live-show-title {
        margin-bottom: $ibmr-space-md;
        font-weight: 400;
        font-size: lerp(36px, 40px, $nav-padding-top-large, 1300px);
    }

    .next-show-title-text {
        margin-bottom: $ibmr-space-xmd;
    }

    .next-live-show-dj {
        // font-size: lerp(16px, 24px, $nav-padding-top-large, 1300px);
        font-size: 1.125rem;
        color: #f3f3f3;
    }

}

@media screen and (min-width: $show-banner-width) {
    .current-show-title-text, .next-show-title-text {
        font-size: calc( 3vw );
    }

    .next-live-show-header-container,
    .next-live-show-header,
    .next-live-show-header-details,
    .next-live-show-date,
    .next-live-show-time {
        font-size: 1.4vw;
    }

    .current-show-dj, .next-live-show-dj {
        font-size: 1.4vw;
    }
}

@media screen and (min-width: 1286px) {
    .next-live-show-header-container,
    .next-live-show-header,
    .next-live-show-header-details,
    .next-live-show-date,
    .next-live-show-time {
        font-size: 18px;
    }
}

@media screen and (min-width: 1429px) {

    .current-show-dj, .next-live-show-dj {
        font-size: 20px;
    }

    .next-live-show-header-container,
    .next-live-show-header,
    .next-live-show-header-details,
    .next-live-show-date,
    .next-live-show-time {
        font-size: 18px;
    }

    // .next-live-show-title {
    //     font-size: 40px;
    // }

    // .next-live-show-dj {
    //     font-size: 24px;
    // }
}