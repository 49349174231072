@import '../common';

$progress-bar-size: 10px;

.audio-player-wrapper {
  width: 100%;
  margin-bottom: 50px;
}

.audio-player {
  bottom: 0%;
  width: 100vw;
  text-align: center;
  background-color: black;
  height: 156px;
  padding: 0px 16px 16px 16px ;
  z-index: 1000;

  &-wrapper {
    
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &:before {
      content: "";
      width: 100%;
      height: 90px;
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 80%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 80%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 80%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(-100%);
      pointer-events: none;
      z-index: 1;
    }
  }

  &.full &-wrapper:before {
    display: none;
  }

  // CANVAS STYLES

  #canvas {
    width: 100%;
    height: 40px;
    margin-bottom: $ibmr-space-xsm;
  }

  // PROGRESS BAR STYLES

  .progress-bar {
    width: 100%;
  }

  .progress-bar-time {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .progress-bar-timeline {
    position: relative;
    position: relative;
    height: $progress-bar-size;
  }

  .progress-bar-timeline-full {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #979797;
    top: 50%;
    transform: translateY(-50%);
  }

  .progress-bar-timeline-current {
    position: absolute;
    width: 100%;
    transition: all 1s ease-in-out;
    height: $progress-bar-size;
    background-color: #f3f3f3;
    
    &::after {
      content:"";
      position: absolute;
      height: $progress-bar-size;
      width: $progress-bar-size;
      background-color: #FF4747;
      right: 0;
    }
  }

  // STREAM INFO + CONTROL STYLES

  .content-wrapper {
    display: flex;
  }

  .stream-wrapper {
    width: calc( 100% - 80px );
    padding-right: 8px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .title-wrapper {
    overflow: hidden;
    position: relative;
    height: auto;

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 20%;
      right: 0;
      bottom: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.64+37,1+72,1+100 */
      background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.64) 37%, rgba(0,0,0,1) 72%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.64) 37%,rgba(0,0,0,1) 72%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.64) 37%,rgba(0,0,0,1) 72%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 ); /* IE6-9 */;
    }
  }

  .subtitle-wrapper {
    text-align: left;
    font-size: 12px;
  }

  .timecode {
    font-weight: bold;
  }

  .active-listeners {
    font-weight: bold;
  }

  // ANIMATION FOR TITLE IF IT EXCEEDS CONTAINER WIDTH

  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-100%);
      opacity: 1;
    }
    51% {
      transform: translateX(-100%);
      opacity: 0;
    }
    52% {
      transform: translateX(100%);
      opacity: 0;
    }
    53% {
      opacity: 1;
    }
    60% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  .title {
    font-weight: 300;
    font-size: 14px;
    text-align: left;
    width: fit-content;

    &.slide {
      animation: slide 14s 3s linear infinite;
    }
  }

  .title-label {
    display: none;
  }

  .title-value {
    white-space: nowrap;
  }

  .content-wrapper {
    text-align: center;
  }

  .controls {
    width: 80px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .control-btn {
    height: auto;
    width: 37%;
    background: transparent;
    border: 0;
    position: relative;

    &:focus {
      outline: 0;
    }

    img {
      height: $ibmr-img-sm;
      width: $ibmr-img-sm;
    }
  }

  .control-btn.volume-btn {
    height: 32px;
    width: 30px;
  }

  .volume-btn img {
    height: $ibmr-img-sm;
    width: $ibmr-img-sm;
  }

  $size: 12px;
  $width: $size + $size/2;
  $transition: .1s all ease-out;

  .control-btn.playback-btn {
    height: 32px;
    width: 30px;
  }

  .icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .btn-anim-enter img {
    transform: scale(.5);
    opacity: 0;
  }
  .btn-anim-enter-active img {
    transform: translateY(0px);
    opacity: 1;
    transition: all 200ms;
  }
  .btn-anim-exit img {
    transform: translateY(0px);
    opacity: 1;
  }
  .btn-anim-exit-active img {
    transform: scale(.5);
    opacity: 0;
    transition: all 200ms;
  }

  .playback-btn .icon-canvas {
    height: 30px;
    width: 30px; 
    position: relative;
    z-index: 1;
  }

  .playback-icon {
    position: relative;
    width: 0;
    height: 0;
    top: 6px;
    right: -8px;
    border-top: ($size - 4px) solid transparent;
    border-bottom: ($size - 4px) solid transparent;
    border-left: ($width - 5px) solid black;
    transition: $transition;
  }
  
  .playback-icon::after {
    content: '';
    position: absolute;
    top: -15px;
    left: -13px;
    position: absolute;
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $width solid white;
    transform: translateX(-2px) translateY(3px);
    z-index: -1;
     transition: 
       width 0s, 
       top 0s,
       left 0s,
       transform .1s ease-out,
       border 0s;
  }
  
  .playback-icon::before {
    content: '';
    position: absolute;
    content: '';
    width: 5px;
    height: 18px;
    outline: 1.5px solid white;
    position: absolute;
    top: -($size);
    left: -3px;
    position: absolute;
    border: 0;
    z-index: -1;
    transform: translateX(0px) translateY(3px);
    opacity: 0;
    transition: $transition;
    transition-delay: 0s;
  }
  
  .icon-playback.pause {
    
    .playback-icon {
      border-color: transparent;
    
      &::after {
        content: '';
        width: 5px;
        height: 18px;
        outline: 1.5px solid white;
        top: -($size);
        left: -1px;
        border: 0;
        z-index: -1;
        transform: translateX(-14px) translateY(3px);
      }
  
      &::before {
         opacity: 1;
      }
    }
  }

  .playback-btn.pulse {
    position: relative;

    &::before {
      pointer-events: none;
      opacity: 0;
      content: "";
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, .5);
      position: absolute;
      border-radius: 100px;
      animation: pulse 3s infinite;
    }
    
    &::after {
      pointer-events: none;
      opacity: 0;
      content: "";
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, .5);
      position: absolute;
      border-radius: 100px;
      animation: pulse-inner 1.5s infinite .1s;
    }
  }
  
  @keyframes pulse {
    0% { transform: scale(0); opacity: .5 }
    50% { transform: scale(10); opacity: 0}
    100% { transform: scale(10); opacity: 0}
  }
  
  @keyframes pulse-inner {
    0% { transform: scale(0); opacity: .5 }
    50% { transform: scale(8); opacity: 0}
    100% { transform: scale(8); opacity: 0}
  }  

  .volume-slider-wrapper {
    position: absolute;
    top: -25%;
    right: 0%;
    height: 7rem;
    width: 40px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: black;
    border-radius: 100px;
    transform: translateY(-80%);
    opacity: 0;
    pointer-events: none;
    transition: .8s all cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 0;

    &.open {
      opacity: 1;
      pointer-events: all;
      transform: translateY(-100%);
    }

    .volume-slider-label {
      position: absolute;
      opacity: 0;
    }

    .volume-slider {
      color: white;
      transform: rotate(270deg);
      transform-origin: 50% 50%;
      width: 5rem;
    }

  }

}

@media screen and (min-width: $nav-padding-top-large) { 

  .audio-player {
    height: 200px;
  }

  .audio-player-wrapper {
    height: 180px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (min-width: $show-banner-width) { 

  .audio-player {
    width: 100%;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .audio-player-wrapper {
    margin: 0 auto;
    padding: 0;
  }
}
  


