@import '../common';
@import '../mixins';

.site-title {
    font-size: rem(20px);
    margin-left: 0.2rem;
    margin: 0;
}

.navigation {
    color: white;
    outline-offset: -1px;
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 500;
    padding-top: 1rem;
}

.navigaton ul {
    padding: 0;
    margin: 0;
}

.navigation li {
    display: inline-block;
    padding: 5px;
}

.navigation-btn {
    z-index: 1;
    padding: 0;
    margin: 0 $ibmr-space-sm 0 $ibmr-space-xmd;
    transform: translateY(#{$ibmr-space-xxsm / 2});

    .navigation-icon {
        width: $ibmr-img--nav;
        height: $ibmr-img--nav;
    }
}

.icon-canvas {
    height: 30px;
    width: 30px; 
    position: relative;
  }
  
  $hamburger-transition: .5s all cubic-bezier(0.165, 0.84, 0.44, 1);
  
  .hamburger {
    position: absolute;
    height: 2px;
    width: 70%;
    background-color: white;
    left: 15%;
    top: 50%;
    transform: translateY(-50%);
    transition: $hamburger-transition;
  
    &::before {
      position: absolute;
      left: 0;
      content: '';
      height: 2px;
      width: 100%;
      background-color: white;
      transform: translateY(-7px);
      transform-origin: 0% 50%;
      transition: $hamburger-transition;
    }
    
    &::after {
      position: absolute;
      left: 0;
      content: '';
      height: 2px;
      width: 100%;
      background-color: white;
      transform: translateY(7px);
      transform-origin: 0% 50%;
      transition: $hamburger-transition;
    }
    
  }
  
  .icon-navigation.open {
    .hamburger {
      
      &::before {
        transform: translateY(.6px) translateX(-1px) rotateZ(-45deg) scaleX(.5);
      }
      
      &::after {
        transform: translateY(-.6px) translateX(-1px) rotateZ(45deg) scaleX(.5);
      }
      
    }
  }

.navigation-list {
    position: absolute;
    height: calc( 100vh - #{$audio-player-size-sm} );
    width: 100vw;
    padding: 0 $ibmr-space-xmd 0;
    font-size: $ibmr-space-xmd + $ibmr-space-xsm/2; // 20px
    font-weight: 300;
    opacity: 0;
    visibility: hidden;
    transition: .5s all cubic-bezier(0.165, 0.84, 0.44, 1);

    .navigation-item {
        margin-bottom: $ibmr-space-xmd;
        transform: translateY(30px);
        opacity: 0;
    }

    @for $i from 1 through 5 { 
        &.open .navigation-item:nth-child(#{$i}) {
            transition: .5s #{$i*.05 + .1}s all cubic-bezier(0.165, 0.84, 0.44, 1);
        }
    }

    &.open {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        height: calc( 100vh - #{$audio-player-size-sm} );
        width: 100vw;
        margin: 0;
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 1;


        .navigation-item {
            transform: translateY(0px);
            opacity: 1;
        }

    }

    &.closing {
        transition: .5s .5s;
        opacity: 0;

        @for $i from 1 through 5 { 
            .navigation-item:nth-child(#{$i}) {
                transition: .4s #{(.25 - $i*.05)}s all cubic-bezier(0.165, 0.84, 0.44, 1);
            }
        }
        
        .navigation-item {
            margin-bottom: $ibmr-space-xmd;
            transform: translateY(30px);
            opacity: 0;
        }

    }

}

.navigation-item-link {

    .navigation-item-link-text {
        position: relative;
    }

    .navigation-item-link-text::after {
        content: '';
        position: absolute;
        height: 1px;
        width: calc( 100% - 2px );
        bottom: -8px;
        left: 2px;
        background-color: white;
        transform: scaleX(0);
        transform-origin: 0% 50%;
        opacity: .3;
        transition: all .4s cubic-bezier(0.165, 0.84, 0.44, 1);
    }



    .navigation-item-link-text:hover::after {
        opacity: 1;
        transform: scaleX(1);
    }

    &:hover {
        .navigation-item-link-img {
            opacity: 1;
        }
    }
    
    &:focus {
        box-shadow: none;

        .navigation-item-link-text::after {
            opacity: 1;
            transform: scaleX(1);
        }
    }

    &.nav-selected {
        font-weight: 500;
        color: white;

        .navigation-item-link-img {
            opacity: 1;
        }

    }
}

.navigation-item-link-share {
    margin-left: auto;
    margin-top: $ibmr-space-xsm/2;
    .navigation-item-link-img {
        margin-right: $ibmr-space-sm;
    }
}

.navigation-item-link-img {
    margin-right: $ibmr-space-md;
    opacity: .7;
    img {
        width: $ibmr-img--nav;
        height: $ibmr-img--nav;
        vertical-align: bottom;
        transform: translateY(#{$ibmr-space-xxsm / 2});
    }
}

.site-title {
    display: flex;
    align-items: center;

    .navigation-item-link.nav-selected { 
        font-weight: 300;
    }
}

.betaTag {
    display: inline-block;
    padding: 0 $ibmr-space-xsm;
    line-height: 18px;
    height: 18px;
    border-radius: 20px;
    background: linear-gradient(to right bottom, #0076FF, rgb(145, 102, 219));
    font-size: .8rem;
    margin-left: $ibmr-space-sm;
    letter-spacing: 3px;
    font-variant: small-caps;
    font-weight: 500;
    white-space: nowrap;
    text-indent: 0.2rem;
}

@media only screen and (min-width: $nav-padding-top-small) {

    .navigation-list {
        .navigation-item {
            margin-left: $ibmr-space-md;
        }
    }
}

@media only screen and (min-width: 630px) {

    .navigation {
        align-items: flex-end;
        // Interpolate navigation padding top between a min and max viewport width
        padding-top: lerp(16px, $ibmr-nav-display-max, $nav-padding-top-small, $nav-padding-top-large);
    }

    .navigation-btn {
        display: none;
    }
    .site-title {
        margin: 0 0 0 $ibmr-space-xmd;
        // Interpolate site title between a min and max viewport width
        font-size: lerp(20px, $ibmr-nav-display-max, $nav-padding-top-small, $nav-padding-top-large);
        font-weight: 300;
        flex-shrink: 0;
        align-items: flex-end;
        
        .navigation-item-link.nav-selected {
            font-weight: 300;
        }
    }

    .betaTag {
        margin-bottom: $ibmr-space-xxsm;
    }

    .navigation-list {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        height: 2rem;
        margin-top: 0;
        margin-bottom: 0;
        transform: translateY(0);
        transition: none !important;
        opacity: 1;
        visibility: visible;

        
        .navigation-item {
            
            opacity: 1;
            margin-left: $ibmr-space-sm;
            margin-bottom: 0;
            font-size: $ibmr-space-xmd;
            padding-top: $ibmr-space-sm;
            transform: translateY(0);
            transition: none !important;
        }

        .navigation-item-link-img {
            display: none;
        }

        .navigation-item-home,
        .navigation-item-home--alt,
        .navigation-item-join {
            display: none;
        }
    }

    .navigation-item-link-share {
        order: 1;
        margin-left: 0;
    }
}

@media only screen and (min-width: $nav-padding-top-large) {
    .navigation {
        padding-left: $ibmr-space-md;
        padding-right: $ibmr-space-md;
        padding-top: 40px;
    }

    .navigation-list .navigation-item {
        font-size: $ibmr-space-md - $ibmr-space-xsm;
        padding-top: $ibmr-space-sm;
    }

    .site-title {
        font-size: $ibmr-nav-display-max;
    }
}
