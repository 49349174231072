@import '../mixins';

.fb-home-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.fb-text {
    font-weight: 100;
    color: #555;
    font-size: 40px;
}

.fb-text-link {
    position: relative;
    white-space: nowrap;
}

.fb-text-link::after {
    content: '';
    position: absolute;
    height: 2px;
    width: calc( 100% - 2px );
    bottom: 2%;
    left: 2px;
    background-color: white;
    transform:translateX(-2.5%) scaleX(0) ;
    transform-origin: 0% 50%;
    opacity: .3;
    transition: all .4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.fb-text-link:hover::after {
    opacity: 1;
    transform: translateX(-2.5%) scaleX(1.04) ;
}

@media screen and (min-width: 350px) {
    .fb-text{
        font-size: lerp(40px, 65px, 350px, 1300px);
    }
}

@media screen and (min-width: 470px) {
    .fb-text{
        max-width: 90%;
    }
}

@media screen and (min-width: 600px) {
    .fb-text{
        max-width: 70%;
    }
}

@media screen and (min-width: 1000px) {
    .fb-text{
        max-width: 60%;
    }
}

@media screen and (min-width: 1200px) {
    .fb-text{
        max-width: 700px;
        font-size: 62px;
    }
}

