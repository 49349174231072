@import '../common';

#calendar {
  font-family: 'IBM Plex Sans';
  width: 100%;
  height: calc( (var(--vh, 1vh) * 100) - #{$navigation-size} - #{$audio-player-size-sm});
  max-height: calc(100vh - #{$navigation-size} - #{$audio-player-size-sm});
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar { width: 0 !important }
}

.fc-month-view .fc-event {
  background-color: #222;
  border: 1px solid #333;
}

.fc-state-active {
  font-weight: bold;
}

.fc-button {
  background: initial;
  color: white;
  border: 0;
  box-shadow: none;
}

.fc td, .fc th {
  border-color: #222;
}

.fc-list-view {
  border: 0;
}

/* LIST VIEW STYLES */

.fc-list-table .fc-event-dot {
  background-color: #222;
}

.fc-unthemed .fc-list-table .fc-list-heading td {
  background-color: initial;
}

.fc-unthemed .fc-list-table .fc-list-item:hover td {
  background-color: #111;
}

.fc-list-table td.fc-widget-header {
  border: 0;
  padding-top: $ibmr-space-md;
}

// Header Styles

.fc-toolbar {
  padding-top: $ibmr-space-md;
  padding-bottom: $ibmr-space-xlg;
  margin-bottom: 0;
  position: fixed;
  width: calc( 100vw - #{$ibmr-space-lg} );
  background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 37%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(0,0,0,1) 37%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,1) 37%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  z-index: 2;

  &.fc-toolbar {
    transform: translateY(-1px);
  }

  h2 {
    font-size: $ibmr-space-xmd + $ibmr-space-xsm/4;
    font-weight: 500;
  }

  .fc-left {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: $ibmr-space-md;
      font-weight: 300;
      font-size: 1.125rem;
      width: 5.25rem;
    }

    .fc-navigateBack-button {
      padding-left: 0;
      padding-right: $ibmr-space-xxsm;
    }
  }

  .fc-center, .fc-clear {
    display: none;
  }
}

.fc-prev-button, 
.fc-next-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $ibmr-space-xlg;
  height: $ibmr-space-xlg;
  padding: 0;
  position: relative;
}

.fc-listMonth-button, .fc-month-button {
  display: none;
}

// replaces default calendar left + right buttons
.calendar-btn {
  img {
    width: $ibmr-space-md;
    height: $ibmr-space-md;
  }
}

.fc-view-container {
  padding-top: $ibmr-space-xxlg;
  margin-bottom: 125px;
}

.fc-scroller {
  height: 100% !important;
}

.fc-list-table {
  font-size: .8rem;

  & td {
    padding: $ibmr-space-xmd 0;
  }

  .fc-widget-header {
    padding-right: 0;
  }

  //remove calendar dot
  .fc-list-item-marker {
    display: none;
  }

  .fc-list-item-time {
    width: 20%;
    padding-right: $ibmr-space-lg;
  }

  .fc-list-item-title {
    width: 75%;
    padding-right: 0;

    a:hover {
      text-decoration: none;
    }
  }
}

.fc-unthemed .fc-list-empty,
.fc-list-empty {
  background-color: black;
  height: 70vh;
}

.fc-unthemed td.fc-today {
  background: #222;
}

@media screen and (min-width: $nav-padding-top-small) {
  .fc-toolbar.fc-toolbar {
    width: calc( 100% - 2.5rem);
  }
}

@media screen and (min-width: $nav-padding-top-large) {
  .fc-toolbar.fc-toolbar {
    width: calc( 100% - 5rem);
  }
  
  .fc-view-container {
    padding-top: 0;
    margin-bottom: 0;
  }

  .fc-head-container.fc-widget-header {
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
  }

  .fc-widget-header tr {
    border-color: transparent;
  }

  th.fc-day-header.fc-widget-header {
    color: #666;
    font-weight: 300;
    border-color: transparent;
    padding-bottom: $ibmr-space-sm;
  }

  .fc-listMonth-button, .fc-month-button {
    display: block;
  }

  .fc-month-view,
  .fc-list-view {
    padding-top: $ibmr-space-xxlg + $ibmr-space-md;
  }

  .fc-list-view {
    padding-bottom: 150px;
  }

}

@media screen and (min-width: 930px){
  .fc-toolbar {
    width: calc( 100% - 1rem );
    padding-top: 2rem;
  }
}

@media screen and (min-width: $show-banner-width) {
  #calendar {
    height: calc(100vh - #{$navigation-size} - #{$audio-player-size-sm});
    max-height: calc(100vh - #{$navigation-size} - #{$audio-player-size-sm});
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
  }
  
  .main-content {
    height: calc(100vh - #{$navigation-size} - #{$audio-player-size-sm});
    max-height: calc(100vh - #{$navigation-size} - #{$audio-player-size-sm});
    overflow: hidden;
    padding: 0 $ibmr-space-xmd;
    flex: 1;
    position: relative;
  }

  .fc-right {
    float: left;
  }

  .fc-toolbar {
    display: flex;
  }

}


