// Spacing Variables
$ibmr-space-xxsm: .25rem; // 4px
$ibmr-space-xsm: .5rem; // 8px
$ibmr-space-sm: .75rem; // 12px
$ibmr-space-xmd: 1rem; // 16px
$ibmr-space-md: 1.5rem; // 24px
$ibmr-space-lg: 2rem; // 32px
$ibmr-space-xlg: 2.5rem; // 40px
$ibmr-space-xxlg: 3rem; //48px

// Font Variables
$ibmr-base-font-size: 16px;
$ibmr-nav-display-max: 24px;

// Variables

// Components
$navigation-size: 60px;
$audio-player-size-sm: 156px;
$audio-player-size-lg: 200px;
$banner-width: 300px;

// UI Icons
$ibmr-img--nav: 1.6rem;
$ibmr-img-sm: $ibmr-space-md - 0.1rem;

// Navigation Padding
$nav-padding-top-small: 550px; 
$nav-padding-top-large: 930px;

// Banner
$show-banner-width: 1200px;