// Sanitize
@import url('https://cdnjs.cloudflare.com/ajax/libs/10up-sanitize.css/8.0.0/sanitize.min.css');

// Global Styles
html,
body {
    font-family: 'IBM Plex Sans', 'Helvatica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-weight: 300;
    margin: .8rem 0;
}

button {
    background: transparent;
    border: 0;

    &:hover {
        cursor: pointer;
    }
}

a {
    text-decoration: none;
    color: #ccc;

    &:visited {
        color: #ccc;
    }

    &:hover {
        color: white;
    }
}

// CSS Tricks Input Slider Reset
// https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/

input[type=range] {
    -webkit-appearance: none;
    /* Hides the slider so that custom slider can be made */
    width: 100%;
    /* Specific width is required for Firefox. */
    background: transparent;
    /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type=range]:focus {
    outline: none;
    /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
}


// Range Input Thumb Styles
$thumb-size: 1.1rem;

/* WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: $thumb-size;
    width: $thumb-size;
    border-radius: $thumb-size;
    background: #ffffff;
    cursor: pointer;
    margin-top: -.42rem;
    /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    /* Add cool effects to your sliders! */
}

/* Firefox */
input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    height: $thumb-size;
    width: $thumb-size;
    border-radius: $thumb-size;
    background: #ffffff;
    cursor: pointer;
}

/* IE */
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    height: $thumb-size;
    width: $thumb-size;
    border-radius: $thumb-size;
    background: #ffffff;
    cursor: pointer;
}

$track-bg: white;
$track-width: 3px;

// Range Input Track Styles
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: $track-width;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: $track-bg;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: $track-bg;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: $track-width;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: $track-bg;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}

input[type=range]::-ms-track {
    width: 100%;
    height: $track-width;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: $track-bg;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]:focus::-ms-fill-lower {
    background: $track-bg;
}

input[type=range]::-ms-fill-upper {
    background: $track-bg;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]:focus::-ms-fill-upper {
    background: $track-bg;
}

// GLOBAL TYPE STYLES

h1,
h2,
h3,
h4,
h5 {
    margin: 1rem 0 1rem;
    font-weight: 400;
    line-height: 1.15;
}

h1 {
    font-size: 1.802rem;
}

h2 {
    font-size: 1.602rem;
}

h3 {
    font-size: 1.424rem;
}

h4 {
    font-size: 1.266rem;
}

h5 {
    font-size: 1.125rem;
}

p {
    color: #666;
    line-height: 1.5;
}