@import '../common';
@import '../mixins';

.banner-container {
    display: none;
    height: 100vh;
    width: 320px;
}

.svg-container {
    background: linear-gradient(to right bottom, #A970FF, #0076FF);
    width: 320px;
    overflow: hidden;
}

svg {
    width: 100%;
    height: 100vh;
    transform: scale(1.2);

    // g#main {
    //     // fix rendering issue in Firefox
    //     filter: blur(0px);
    // }

    // g#group-1 {
    //     animation: g1 20s infinite;
    // }

    // g#group-2 {
    //     animation: g2 16s 1s infinite;
    // }

    // g#group-3 {
    //     animation: g3 25s 2s infinite;
    // }
}

// @keyframes g1 {
//     // 0% { transform: translate3d(0, 0, 0) }
//     // 50% { transform: translate3d(300px, 300px, 0) }
//     // 100% { transform: translate3d(0px, 0px, 0) }
//     0% { transform: translateX(0) translateY(0);}
//     50% { transform: translateX(100px) translateY(-100px);}
//     100% { transform: translateX(0) translateY(0);}
// }

// @keyframes g2 {
//     // 0% { transform: translate3d(0, 0, 0) }
//     // 50% { transform: translate3d(300px, 300px, 0) }
//     // 100% { transform: translate3d(0px, 0px, 0) }
//     0% { transform: translateX(0) translateY(0);}
//     50% { transform: translateX(-70px) translateY(70px);}
//     100% { transform: translateX(0) translateY(0);}
// }

// @keyframes g3 {
//     // 0% { transform: translate3d(0, 0, 0) }
//     // 50% { transform: translate3d(300px, 300px, 0) }
//     // 100% { transform: translate3d(0px, 0px, 0) }
//     0% { transform: translateX(0) translateY(0);}
//     50% { transform: translateX(50px) translateY(-50px);}
//     100% { transform: translateX(0) translateY(0);}
// }

// @keyframes g1 {
//     // 0% { transform: translate3d(0, 0, 0) }
//     // 50% { transform: translate3d(300px, 300px, 0) }
//     // 100% { transform: translate3d(0px, 0px, 0) }
//     0% { transform: translateX(0) translateY(0);}
//     50% { transform: translateX(10px) translateY(-10px);}
//     100% { transform: translateX(0) translateY(0);}
// }

// @keyframes g2 {
//     // 0% { transform: translate3d(0, 0, 0) }
//     // 50% { transform: translate3d(300px, 300px, 0) }
//     // 100% { transform: translate3d(0px, 0px, 0) }
//     0% { transform: translateX(0) translateY(0);}
//     50% { transform: translateX(-7px) translateY(7px);}
//     100% { transform: translateX(0) translateY(0);}
// }

// @keyframes g3 {
//     // 0% { transform: translate3d(0, 0, 0) }
//     // 50% { transform: translate3d(300px, 300px, 0) }
//     // 100% { transform: translate3d(0px, 0px, 0) }
//     0% { transform: translateX(0) translateY(0);}
//     50% { transform: translateX(5px) translateY(-5px);}
//     100% { transform: translateX(0) translateY(0);}
// }

@media (min-width: $show-banner-width) {
    .banner-container, .svg-container {
        width: 20vw;
    }
}