@import 'common';

body {
  background: black;
  color: white;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position:fixed; /* prevent overscroll bounce*/
  overflow-y: scroll;
  scrollbar-width: none;
}

a, button {
  transition: .4s ease-in-out;
  &:focus {
    outline: 0;
    // box-shadow: 0 0 0 2px rgba(255,255,255,1);
  }
}

button {
  padding: 5px;
  margin: 5px;
}

#root {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
}

.App {
  width: 100vw;
  max-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.App-content {
  display: flex;
  flex-direction: column;
  height: calc( 100vh - #{$audio-player-size-sm} );
  height: calc( var(--vh, 1vh) * 100 - #{$audio-player-size-sm} );
  max-height: calc( var(--vh, 1vh) * 100 - #{$audio-player-size-sm});
}

// Main app layout

.non-error-content {
  display: flex;
}

.non-banner-content {
  overflow: hidden;
}

.navigation-wrapper {
  flex: 0 0;
  position: relative;
  min-height: $navigation-size;
  width: 100%;
  .navigation {
    width: 100%;
  }
}

// react router animations

.page {
  position: absolute;
  width: calc( 100% - 2.5rem );
  &::-webkit-scrollbar { width: 0 !important; background: transparent; }
}

.page-enter {
  opacity: 0;
  transform: translateY(30px);
}

.page-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: 
    opacity 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms, 
    transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
}

.page-exit {
  opacity: 1;
  transform: translateY(0px);
}

.page-exit-active {
  opacity: 0;
  transform: translateY(0);
  transition: opacity 200ms, transform 200ms ;
}

.main-content {
  height: calc(100vh - #{$navigation-size} - #{$audio-player-size-sm});
  max-height: calc(100vh - #{$navigation-size} - #{$audio-player-size-sm});
  overflow: hidden;
  padding: 0 $ibmr-space-xmd;
  flex: 1 1;
  position: relative;
  &::-webkit-scrollbar { width: 0 !important; background: transparent; }
}

@media screen and (min-width: $nav-padding-top-large) {

  .page {
    width: calc( 100% - 5rem );
  }
  
  .main-content {
    padding: 0 $ibmr-space-xlg;
  }

  .App-content {
    height: calc( 100vh - #{$audio-player-size-lg} );
    height: calc( var(--vh, 1vh) * 100 - #{$audio-player-size-lg} );
    max-height: calc( var(--vh, 1vh) * 100 - #{$audio-player-size-lg});
  }

}

// display the decorative banner at a certain width
@media only screen and (min-width: $show-banner-width) {

  .audio-player {
    right: 0;
  }

  .App-content {
    margin: 0 auto;
  }

  .banner-container {
      display: block;
  }

}

// Pace.js styles

@keyframes FadeAnim {
  0% {
     opacity: 0;
  }
  100% {
     opacity: 1;
  }
}