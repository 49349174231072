
@import './common';

$baseFontSize: $ibmr-base-font-size;
// Px to Rem Function
@function rem($px, $base: $baseFontSize) {

    // if no units provided, give units
    @if (unitless($px)) {
        $px: $px * 1px;
    }

    @if (unitless($base)) {
        $base: $base * 1px;
    }

    @return $px / $base * 1rem;
}

// Linear Interpolation Function 
// https://css-tricks.com/between-the-lines/
@function lerp($from, $to, $fromWidth, $toWidth) {
    $slope: ($to - $from) / ($toWidth - $fromWidth);
    $base: $from - $slope * $fromWidth;

    @return calc(#{$base} + #{100vw * $slope});
}
